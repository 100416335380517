import User from '@/shared/modules/user/user.model'
import Project from '@/shared/modules/project/models/project.model'
import IEmployeeResponse from '@/shared/modules/employee/employee-response.interface'

export default class Employee extends User {
  projects!: Project[]

  transform(data: IEmployeeResponse): void {
    super.transform(data)

    this.projects = this.setRelation(Project, data.projects)
  }
}

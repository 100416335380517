import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IProjectBudgetResponse, { IProjectBudgetBalance } from '@/shared/modules/project/interfaces/project-budget-response.interface'

export default class ProjectBudget extends Model<IModelResponse> {
  hourlyPrice!: number
  agreedPrice!: number
  estimatedHours!: number
  extraPrice!: number
  usedHours!: number
  usedWithoutExtraHours!: number
  usedExtraHours!: number
  balance!: IProjectBudgetBalance

  transform(data: IProjectBudgetResponse): void {
    super.transform(data)

    this.hourlyPrice = data.hourly_price
    this.agreedPrice = data.agreed_price
    this.estimatedHours = data.estimated_hours
    this.extraPrice = data.extra_price
    this.usedHours = data.used_hours
    this.usedWithoutExtraHours = data.used_without_extra_hours
    this.usedExtraHours = data.used_extra_hours
    this.balance = data.balance
  }
}

import Field from '@/shared/classes/form/field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import { FieldTypes } from '@/shared/components/form/field-types'
import { IFilter, ILoadItemsProps } from '@/shared/interfaces/classes/form.interfaces'
import http from '@/shared/http'
import IResponse from '@/shared/interfaces/modules/response.interface'
import { getQueryName } from '@/shared/helpers/query.helper'

export default class SelectField extends Field {
  type: FieldTypes = FieldTypes.select
  items: SelectItem[] = []

  setItems(items: SelectItem[]): SelectField {
    this.items = items
    return this
  }

  isMultiple(): SelectField {
    this.type = FieldTypes.multipleSelect
    return this
  }

  loadItems(loadItemsProps: ILoadItemsProps): this {
    let endpoint = loadItemsProps.endpoint

    const searchParams = new URLSearchParams('')
    if (loadItemsProps.perPage) searchParams.set('per_page', loadItemsProps.perPage.toString())
    else searchParams.set('per_page', '500')

    if (loadItemsProps.filters) {
      loadItemsProps.filters.forEach((filter: IFilter) => {
        searchParams.set(getQueryName(filter.type, filter.name), filter.value)
      })
    }

    http.get(`${ endpoint }?${ searchParams.toString() }`)
      .then((response: IResponse<any>) => {
        this.setItems(response.data.data.map((item: any, index: number) => {
          return new SelectItem()
            .setTitle(loadItemsProps.titleFunction ? loadItemsProps.titleFunction(item, index) : item[loadItemsProps.title])
            .setValue(item[loadItemsProps.value])
        }))
      })

    return this
  }
}

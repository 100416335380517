








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import { CompanyRoutes } from '@/shared/router/company'
import FormBase from '@/shared/classes/form/form-base'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { LOCALES } from '@/shared/constants/locales.constants'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Form from '@/shared/components/form/Form.vue'
import Employee from '@/shared/modules/employee/employee.model'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model';

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EmployeesCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.$store.getters[CompanyGetters.getCompany].slug }/employees`)
    .setFiles(true)
    .setModel(Employee)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('avatar')
        .setTitle(__('company.views.employees.form.avatar'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field()
        .setKey('first_name')
        .setEntryKey('firstName')
        .setTitle(__('company.views.employees.form.first_name'))
        .setSize(FieldSizes.half)
        .isRequired(),
      new Field()
        .setEntryKey('lastName')
        .setKey('last_name')
        .setTitle(__('company.views.employees.form.last_name'))
        .setSize(FieldSizes.half)
        .isRequired(),
      new Field()
        .setType(FieldTypes.email)
        .setKey('email')
        .setTitle(__('company.views.employees.form.email'))
        .isRequired(),
      new Field()
        .setKey('phone')
        .setTitle(__('company.views.employees.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('birthDate')
        .setKey('birth_date')
        .setTitle(__('company.views.employees.form.birth_date'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('address')
        .setTitle(__('company.views.employees.form.address')),
      new SelectField()
        .setKey('role_uuid')
        .setEntryKey('roleUuid')
        .setTitle(__('company.views.employees.form.role'))
        .loadItems({
          endpoint: '/roles',
          value: 'uuid',
          title: 'name',
          filters: [
            {
              type: FilterOperators.in,
              name: 'level',
              value: [
                RoleLevel.employee,
                RoleLevel.leader,
                RoleLevel.manager,
              ]
            }
          ]
        })
        .setSize(FieldSizes.half)
        .isRequired(),
      new SelectField()
        .setKey('position_uuid')
        .setEntryKey('positionUuid')
        .setTitle(__('company.views.employees.form.position'))
        .loadItems({ endpoint: '/positions', value: 'uuid', title: 'name',  })
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('clothingSize')
        .setKey('clothing_size')
        .setTitle(__('company.views.employees.form.clothing-size'))
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('shoeSize')
        .setKey('shoe_size')
        .setTitle(__('company.views.employees.form.shoe-size'))
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey('defaultLocale')
        .setKey('default_locale')
        .setTitle(__('company.views.employees.form.default_locale'))
        .setItems(Object.keys(LOCALES).map((key: string): SelectItem => new SelectItem()
          .setTitle(LOCALES[key].title)
          .setValue(key)
        )),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('workFrom')
        .setKey('work_from')
        .setTitle(__('company.views.employees.form.work_from'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('workTo')
        .setKey('work_to')
        .setTitle(__('company.views.employees.form.work_to'))
        .setSize(FieldSizes.half),
    ])
    .setInitialValues({
      statusUuid: User.STATUS_ACTIVE,
    })
    .setSubmit({
      text: __('company.views.employees.create.form.submit')
    })
    .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.employeesIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.employees.create.form.on-success')
    })
    this.goBack()
  }
}

import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IProjectLocationResponse from '@/shared/modules/project/interfaces/project-location-response.interface'
import Country from '@/shared/modules/country/country.model'

export default class ProjectLocation extends Model<IModelResponse> {
  street!: string
  number!: string
  postCode!: string
  city!: string
  municipality!: string
  gnr!: string
  bnr!: string
  lat!: number
  lng!: number
  radius!: number

  countryUuid!: string
  country!: Country

  transform(data: IProjectLocationResponse): void {
    super.transform(data)

    this.street = data.street
    this.number = data.number
    this.postCode = data.post_code
    this.city = data.city
    this.municipality = data.municipality
    this.bnr = data.bnr
    this.gnr = data.gnr
    this.lat = data.lat
    this.lng = data.lng
    this.countryUuid = data.country_uuid
    this.radius = data.radius
    this.country = this.setRelation(Country, data.country)
  }
}

export default class SelectItem {
  value!: string
  title!: string
  deletedAt!: string
  meta!: any

  setValue(value: any): SelectItem {
    this.value = value
    return this
  }

  setTitle(title: string): SelectItem {
    this.title = title
    return this
  }

  setDeletedAt(deletedAt: string): SelectItem {
    this.deletedAt = deletedAt
    return this
  }

  setMeta(meta: any): SelectItem {
    this.meta = meta
    return this
  }
}

import Field from '@/shared/classes/form/field'
import { getQueryName } from '@/shared/helpers/query.helper'

export enum FilterOperators {
  equals = 'equals',
  like = 'like',
  in = 'in',
  notIn = 'notIn',
  nulled = 'nulled',
  notNulled = 'notNulled',
  gte = 'gte',
  lte = 'lte',
  equalsYear = 'equalsYear'
}

export default class DataTableFilter {
  field!: Field
  title!: string
  keyIsPrimary: boolean = false
  showCondition: boolean = true
  visibleValuesCount: number = 2
  operator: FilterOperators = FilterOperators.equals

  setField(field: Field): this {
    this.field = field
    return this
  }

  setTitle(title: string): DataTableFilter {
    this.title = title
    return this
  }

  setOperator(operator: FilterOperators): DataTableFilter {
    this.operator = operator
    return this
  }

  setKeyIsPrimary(): this {
    this.keyIsPrimary = true
    return this
  }

  setShowCondition(showCondition: boolean): this {
    this.showCondition = showCondition
    return this
  }

  setVisibleValuesCount(visibleValuesCount: number): this {
    this.visibleValuesCount = visibleValuesCount
    return this
  }

  getQueryName(): string {
    return getQueryName(this.operator, this.field.key)
  }
}

import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Company from '@/shared/modules/company/company.model'
import IProjectResponse from '@/shared/modules/project/interfaces/project-response.interface'
import ProjectBudget from '@/shared/modules/project/models/project-budget.model'
import ProjectLocation from '@/shared/modules/project/models/project-location.model'
import Client from '@/shared/modules/client/client.model'
import User from '@/shared/modules/user/user.model'
import Status from '@/shared/modules/status/status.model'

export default class Project extends Model<IModelResponse> {
  static ALIAS: string = 'project'

  name!: string
  description!: string
  workStartAt!: string
  workEndAt!: string

  statusUuid!: string
  responsibleUuid!: string
  companyUuid!: string
  budgetUuid!: string
  clientUuid!: string
  locationUuid!: string
  readableAddress!: string

  status!: Status
  responsible!: User
  company!: Company
  budget!: ProjectBudget
  client!: Client
  location!: ProjectLocation

  transform(data: IProjectResponse): void {
    super.transform(data)

    this.name = data.name
    this.description = data.description
    this.workStartAt = data.work_start_at
    this.workEndAt = data.work_end_at

    this.statusUuid = data.status_uuid
    this.responsibleUuid = data.responsible_uuid
    this.locationUuid = data.location_uuid
    this.budgetUuid = data.budget_uuid
    this.clientUuid = data.client_uuid
    this.companyUuid = data.company_uuid
    this.readableAddress = data.readable_address

    this.status = this.setRelation(Status, data.status)
    this.responsible = this.setRelation(User, data.responsible)
    this.location = this.setRelation(ProjectLocation, data.location)
    this.budget = this.setRelation(ProjectBudget, data.budget)
    this.client = this.setRelation(Client, data.client)
    this.company = this.setRelation(Company, data.company)
  }
}
